import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Feathers } from '../feathers.service';
import { BaseService } from '../base.service';
import * as download from 'downloadjs';
import { take, tap } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import { Observable } from 'rxjs';

@Injectable()
export class FileService extends BaseService<any> {

  constructor(
    protected feathers: Feathers,
    protected http: HttpClient,
    protected auth: AuthService,
  ) {
    super(feathers, 'uploads');
  }

  // use REST for upload
  create(data:any):Observable<any> {
    const options: any = { 
      headers :  new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.auth.authData.accessToken
      })
    }
    return this.http.post(`${this.feathers.instanceUrl}/uploads`, data, options);
  }

  // download(id:string) {      
  //   super.get(id).pipe(take(1)).subscribe(data => download(data.uri, data.id));
  // }

  download(id:string) {
    const options: any = { 
      headers :  new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.auth.authData.accessToken
      })
    }
    return this.http.get(`${this.feathers.instanceUrl}/uploads/${id}`, options).pipe(
      tap((data:any) => {
        download(data.uri, data.id)
      })
    );
  }

  getSrc(id:string) {
    return super.get(id).pipe(take(1));
  }

}
