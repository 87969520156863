import { ChangeDetectorRef, Component, Input, OnChanges, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { retry, take } from 'rxjs/operators';
// Services
import { ChannelService } from 'src/app/services/channel/channel.service';
import { FileService } from 'src/app/services/file/file.service';
import { MessageService } from 'src/app/services/message/message.service';
import { SessionService } from 'src/app/services/session/session.service';

@Component({
  selector: 'vc-session-settings',
  templateUrl: './session-settings.component.html',
  styleUrls: ['./session-settings.component.scss'],
})
export class SessionSettingsComponent implements OnChanges {
    @Input() session: any;
    @ViewChild('fileInput', {static: false}) fileInput: any;
    @ViewChild('bannerInput', {static: false}) bannerInput: any;
    uploading:boolean = false;
    displayTimePicker: boolean = false;
    channels: any[] = [];
    eventId: string = 'VdoUjTaifwXRdVsI'; // TODO get eventId from service

    constructor(
        private toastr: ToastrService,
        private messageService: MessageService,
        private sessionService: SessionService,
        private fileService: FileService,
        private channelService: ChannelService,
        private chRef: ChangeDetectorRef,
    ) {}

    ngOnChanges() {
        this.getChannels();
        setTimeout(()=> {
            if (this.session) {
                if (!(this.session.day instanceof Date)) {                
                    this.session.day = new Date(this.session.day);
                }
                if (!(this.session.start_time instanceof Date)) {         
                    this.session.start_time = new Date(this.session.start_time);
                }
                if (!(this.session.end_time instanceof Date)) {            
                    this.session.end_time = new Date(this.session.end_time);
                }
                if (!this.session.sponsor) {
                    this.session.sponsor = {};
                }
                this.displayTimePicker = true;
            }
        }, 0);
    }
    
    updateSession(session:any) {
        if (!session) {
            this.toastr.warning('Missing session details.');
            return;
        }
        let copy = Object.assign({}, session);
        delete copy.uploads;
        this.sessionService.patch(session._id, copy).subscribe(
            data => this.toastr.success(`Updated the session "${session.name}".`),
            data => this.toastr.error(`Could not update the session information.`)
        )
    }    

    deleteSession(session:any) {
        if (!session) {
            this.toastr.warning('Missing session details.');
            return;
        }
        this.sessionService.remove(session._id).subscribe(
            data => this.toastr.success(`Deleted the session "${session.name}".`),
            data => this.toastr.error(`Could not delete the session.`)
        )
    }
    
    toggleChat() {
        this.sessionService.patch(this.session._id, {chat_enabled: this.session.chat_enabled ? false: true, event_id: this.session.event_id});
    }

    clearChat() {
        this.messageService.remove(null, {
            query: {
                session_id: this.session._id,
                event_id: this.session.event_id,
            }
        }).subscribe(
        data => this.toastr.success('Deleted chat history.'),
        error => {
            this.toastr.error('Could not delete the chat history.')
            console.error(error)
        }
        );
    }

    uploadFile(file:File, isBanner:boolean = false) {
        const filesize = Number(((file.size/1024)/1024).toFixed(4)); // MB
        if (filesize > 140) {
            this.toastr.warning('Filesize is too big (max 140MB).');
            return;
        }
        this.uploading = true;
        const _this = this;
        const reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = function() {
            const base64 = `data:${file.type};base64,${btoa(String(reader.result))}`;            
            _this.fileService.create({
                id: file.name,
                uri: base64,
                event_id: _this.session.event_id,
                session_id: isBanner ? null : _this.session._id,
            }).subscribe(
                data => {
                    _this.uploading = false;
                    _this.toastr.success('Upload was successful.');
                    if (!isBanner) {
                        _this.fileInput.nativeElement.value = null;
                        data.upload_id = data.id;
                        !_this.session.uploads || !Array.isArray(_this.session.uploads) ? _this.session.uploads = [data] : _this.session.uploads.push(data);
                    } else {
                        _this.bannerInput.nativeElement.value = null;
                        _this.session.sponsor.banner_url = data.id;
                        _this.session.sponsor.banner_active = true;
                        _this.updateSession(_this.session);
                    }
                },
                error => {
                    _this.uploading = false;
                    console.error('[Error @SessionSettingsComponent] uploadFile()', error)
                    _this.toastr.error(`Could not upload file.`, 'Error!');
                }
            )
        };
        reader.onerror = function() {
            console.error('[Error @SessionSettingsComponent] uploadFile() - reader. Code 136');
        };
    }

    downloadFile(upload:any) {
        const toast = this.toastr.info(`Download started: ${upload.upload_id}`);
        this.fileService.download(upload.upload_id).subscribe(
            data => {
                this.toastr.remove(toast.toastId);
                this.toastr.success(`Downloaded file ${upload.upload_id}.`)
            },
            error => {
              this.toastr.error(`Could not download file file ${upload.upload_id}.`);
              console.error(error);
            }
          );
    }

    deleteFile(upload:any, isBanner:boolean = false) {
        this.fileService.remove(upload.upload_id).subscribe(
            data => {
                this.toastr.success(`Delete file "${upload.upload_id}".`);
                if (!isBanner) {
                    const index = this.session.uploads.findIndex(u => u.upload_id == upload.upload_id);
                    if (index > -1) {
                        this.session.uploads.splice(index, 1);
                    }
                } else {
                    this.session.sponsor.banner_url = null;
                    this.updateSession(this.session);
                }
            },
            error => {
                console.error('[Error @SessionSettingsComponent] deleteFile:', error);
                this.toastr.error('Could not delete the file.');
            }
        )
    }
     
    toggleBanner() {
        if (!this.session.sponsor) {
            return;
        }
        if (this.session.sponsor.banner_active) {
            this.session.sponsor.banner_active = false;
        } else {
            this.session.sponsor.banner_active = true;
        }
        const data = {
            sponsor: this.session.sponsor
        }
        this.sessionService.patch(this.session._id, data);
    }

    getChannels() {
        this.channelService.watch({
        query: { 
            event_id: this.eventId,
            $limit: 25,
        }
        }).pipe(
            take(1),
        ).subscribe(
        data => this.channels = data,
        error => {
            console.error(error);
            this.toastr.error('Could not load channels!');
        }
        );
    }

    getChannelName(channelId: any) {
        const channel = this.channels.find(c => c._id === channelId);
        return channel ? channel.name : channelId;
    }

    moveSessionToChannel(channelId:any) {
      // check if channel exists
      const newChannel = this.channels.find(c => c._id === channelId);
      if (!newChannel) {
        this.toastr.error('ChannelId is invalid.', 'Could not move session!');  
        return;
      }
      // check if session is active in channel
      const oldChannel = this.channels.find(c => c._id === this.session.channel_id);
      if (!oldChannel) {
        this.toastr.error('Could not move session! [2]');  
        return;
      }
      if (oldChannel.active_session_id === this.session._id) {
        this.toastr.error(`Session is set as active session in channel "${oldChannel.name}".`, 'Could not move session!');  
        return;
      }
      // update session
      this.session.channel_id = channelId;
      this.updateSession(this.session);
    }

}