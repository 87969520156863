import { Injectable } from '@angular/core';
import { Feathers } from '../feathers.service';
import { BaseService } from '../base.service';
import { map } from 'rxjs/operators';

@Injectable()
export class SurveySubmissionService extends BaseService<any> {

  constructor(
    protected feathers: Feathers
  ) {
    super(feathers, 'survey-submission');
  }

  watchOne(filter: any) {
    return super.watch(filter).pipe(
      map(e => e[0]),
    );
  }

}
