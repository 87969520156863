import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'vc-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss']
})
export class InformationComponent implements OnInit {
  programmLink: string;

  constructor(
    private authService: AuthService,
  ) { }

  ngOnInit() {
    this.programmLink = this.authService.hasUser() ? '/event/VdoUjTaifwXRdVsI' : '/login';
  }

}
