import { Component, Input, ChangeDetectionStrategy, OnChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import * as Clappr from 'clappr'
import { fromEvent, timer } from 'rxjs';
import { debounce } from 'rxjs/internal/operators/debounce';
import { ExhibitorService } from 'src/app/services/exhibitor/exhibitor.service';

@Component({
  selector: 'vc-stream-player',
  templateUrl: './stream-player.component.html',
  styleUrls: ['./stream-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StreamPlayerComponent implements OnChanges {
    @Input() rawUrl: string;
    @Input() autoplay: boolean = true;
    @Input() trackingParameters: string[]; //[exhibitorName, action]
    url:SafeResourceUrl;
    playerType: string;
    muxID: string; 

    constructor(
        private sanitizer: DomSanitizer,
        private exhibitorService: ExhibitorService,
    ) {
        this.muxID = 'player' + new Date().getTime()+Math.random().toString(36).substring(7) 
    }

    ngOnChanges() {
        this.muxID = 'player' + new Date().getTime()+Math.random().toString(36).substring(7) 
        if (this.rawUrl.includes('stream.mux')) {
            this.playerType = 'mux';
            setTimeout(() => {
                const e: any = document.querySelector(`#${this.muxID}`);
                e.innerHTML = '';
                let playbackId = '';
                try {
                    playbackId = this.rawUrl.split('/').slice(-1).pop().split('.')[0];
                } catch (e) {
                    console.error('Could not determine playbackId.', e);
                }
                const _this = this;
                new Clappr.Player({
                    source: this.rawUrl,
                    poster: playbackId ? `https://image.mux.com/${playbackId}/animated.gif` : '',
                    parentId: `#${this.muxID}`,
                    width: e.offsetWidth,
                    height: e.offsetWidth/1.77,
                    autoPlay: this.autoplay,
                    mediacontrol: {
                        buttons: "#66B2FF"
                    },
                    events: {
                        onPlay: function() { 
                            //Fired when player starts to play
                            if (_this.trackingParameters && _this.trackingParameters[0] && _this.trackingParameters[1]) {
                                _this.exhibitorService.trackEvent(_this.trackingParameters[0] , 'video play', _this.trackingParameters[1])
                            }
                         },
                        onPause: function() {
                            //Fired when player pauses
                            if (_this.trackingParameters && _this.trackingParameters[0] && _this.trackingParameters[1]) {
                                _this.exhibitorService.trackEvent(_this.trackingParameters[0] , 'video paue', _this.trackingParameters[1])
                            }
                         },
                        onStop: function() {
                            //Fired when player stops
                            if (_this.trackingParameters && _this.trackingParameters[0] && _this.trackingParameters[1]) {
                                _this.exhibitorService.trackEvent(_this.trackingParameters[0] , 'video stop', _this.trackingParameters[1])
                            }
                        },
                        onEnded: function() {
                            //Fired when player ends the video
                            if (_this.trackingParameters && _this.trackingParameters[0] && _this.trackingParameters[1]) {
                                _this.exhibitorService.trackEvent(_this.trackingParameters[0] , 'video ended', _this.trackingParameters[1])
                            }
                         },
                        onError: function(e) {
                          // Here the code to handle the error
                          console.error(e)
                        }
                      }
                }), 100;
            });          
            fromEvent(window, 'resize').pipe(debounce(() => timer(100))).subscribe(data => {
                const e: any = document.querySelector(`#${this.muxID}`)
                if (e) {
                    const playerDiv:any = document.querySelector(`#${this.muxID} div`);
                    playerDiv.style.width = e.offsetWidth+'px';
                    playerDiv.style.height = e.offsetWidth/1.77+'px';
                }
            });
        } else {
            this.playerType = 'iframe';
            this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.rawUrl);
            fromEvent(window, 'resize').pipe(debounce(() => timer(100))).subscribe(data => {
                this.resizeIframe()
            });
        }
    }

    ngAfterViewInit() {
        if (this.playerType === 'iframe') {
            this.resizeIframe();
        }
    }

    resizeIframe() {
        const e: any = document.querySelector('#playerFrame')
        if (e) {
            const playerDiv:any = document.querySelector('#playerFrame iframe');
            playerDiv.width = e.offsetWidth+'px';
            playerDiv.height = e.offsetWidth/1.77+'px';
        }
    }  
}
