import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import exhibitors from '../../../assets/sponsoren.json';
declare var window: any;

@Injectable({
  providedIn: 'root'
})
export class ExhibitorService {
exhibitors: any; 

  constructor() {
    this.exhibitors = exhibitors.exhibitors 
  }

  get() {
    return of(this.exhibitors);
  }

  getOne(id: number) {
    return of(this.exhibitors.find(e => e.id === id));
  }

  trackEvent(category: string, action:string, name: string = null, value:any = new Date().getTime()) {
    if (window._paq && category && action) {
      // _paq.push(['trackEvent', 'test', 'Clicked', new Date().getTime()]);
      //            matmoto.event, event.category, event.action, event.name, event.value  
      // console.log(category, action, name, value)
      window._paq.push(['trackEvent', category, action, name]); //, value]);
    }
  }

}
