import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Feathers } from '../../services/feathers.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'vc-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  constructor(
    private feathers: Feathers,
    private router: Router,
    private toastr: ToastrService,
  ) {}

  login(email: string, password: string) {
    if (!email || !password) {
      this.toastr.error('Incomplete credentials!')
      return;
    }

    // try to authenticate with feathers
    this.feathers.authenticate({
      strategy: 'local',
      email,
      password
    })
      // navigate to base URL on success
      .then(() => {
        this.router.navigate(['/']).then(e => {
          // TODO user differenc approach to refresh app.component 
          window.location.reload();
        });
      })
      .catch(err => {
        this.toastr.error('Wrong credentials!')
      });
  }

}
