import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { SurveyService } from 'src/app/services/survey/survey.service';
import { take, takeUntil } from 'rxjs/operators';
import { SurveySubmissionService } from 'src/app/services/survey/survey-submission.service';
import { AuthService } from 'src/app/services/auth.service';
import { Observable, Subject } from 'rxjs';
declare var $: any;

@Component({
  selector: 'vc-form-renderer',
  templateUrl: './form-renderer.component.html',
  styleUrls: ['./form-renderer.component.scss']
})
export class FormRendererComponent implements OnInit, OnDestroy {
  $destroyed: Subject<boolean> = new Subject<boolean>();
  eventId: string = 'VdoUjTaifwXRdVsI'; // TODO get eventId from service
  formRef: any;
  survey: any;
  submission: any;
  defaults = {
    container: false,
    formData: [],
    dataType: 'json', // 'xml' | 'json'
    label: {
      formRendered: 'Form Rendered',
      noFormData: 'No form data.',
      other: 'Other',
      selectColor: 'Select Color'
    },
    render: true,
    notify: {
      error: function(message) {
        return console.error(message);
      },
      success: function(message) {
        return console.log(message);
      },
      warning: function(message) {
        return console.warn(message);
      }
    }
  }

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private toastr: ToastrService,
    private surveyService: SurveyService,
    private surveySubmissionService: SurveySubmissionService,
  ) { }

  ngOnInit() {
    this.route.params.subscribe(
      (data: any) => {
        if (Object.keys(data).includes('surveyId') && data['surveyId']) {          
          // init form
          const initForm = (formData:any) => {
            this.defaults.formData = formData;
            if ($('#formRenderer').length > 0) {
              this.formRef = $('#formRenderer').formRender(this.defaults);
            } else {
              setTimeout(initForm, 100);
            }
          }
          // todo load data
          this.surveyService.get(data['surveyId']).pipe(take(1)).subscribe(
            survey => {
              this.survey = survey;
              if (!survey.public) {
                this.router.navigate(['/surveys']);
              }
              initForm(survey.formData);
              // check if user submitted the form before
              this.surveySubmissionService.watchOne({
                query: {
                  user_id: this.authService.getUser()._id,
                  survey_id: this.survey._id,
                  event_id: this.eventId
                }
              }).pipe(
                takeUntil(this.$destroyed.asObservable()),
              ).subscribe(
                submission => {
                  if ((submission !== undefined) && submission._id) {
                    initForm(submission.userData);
                    this.submission = submission;
                    // fix loading bug for checkbox-group
                    submission.userData.forEach(element => {
                      if (element.type === 'checkbox-group') {
                        element.userData.forEach(entry => {
                          $.each($(`[value=${entry}]`),(key, value) => {
                            if ($(value).attr('id').includes(element.name)) {
                              $(value).click();
                            }
                          });
                        });
                      }
                    });
                  }
                }
              );
            },
            error => {
              this.toastr.error('Could not load survey data', 'Survey not found!');
              setTimeout(() => this.location.back() ,350)
            }
          )
        } else {
          this.toastr.error('No matching survey found!');
          this.location.back();
        }
      },
      error => {
        this.toastr.error('Cloud not load survey!');
        this.location.back();
      }
    );
  }
  
  ngOnDestroy() {
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  onSubmit(form: NgForm) {
    for (let i = 0; i < this.formRef.userData.length; i++) {
      if (this.formRef.userData[i].required && !this.formRef.userData[i].userData) {
        this.toastr.error(`Es ist eine Eingabe für das Feld "${this.formRef.userData[i].label}" nötig.`, `Umfrage unvollständig`);
        return;
      }
      // validation
      if (this.formRef.userData[i].validation) {
        for (let v=0; v < this.formRef.userData[i].validation.length; v++) {
          // console.log(this.formRef.userData[i].validation[v])
          if (!Array.isArray(this.formRef.userData[i].validation[v]) || (this.formRef.userData[i].validation[v].length !== 3)) {
            this.toastr.error('Wrong validation format!');
            return;
          }
          switch (this.formRef.userData[i].validation[v][0]) {
            case "max-length":
              if (!this.formRef.userData[i].userData) {
                this.toastr.error('There are required field missing.');
                return;
              }
              if (this.formRef.userData[i].userData.length > this.formRef.userData[i].validation[v][1]) {
                this.toastr.error(this.formRef.userData[i].validation[v][2], `Frage: "${this.formRef.userData[i].label}"`);
                return;
              }
              break;
          }
        }
      }
    }
    // submit data
    let obs: Observable<any>;
    if (!this.submission) {
      obs = this.surveySubmissionService.create({
        survey_id: this.survey._id,
        user_id: this.authService.getUser()._id,
        event_id: this.eventId,
        userData: this.formRef.userData
      });
    } else {
      obs = this.surveySubmissionService.patch(this.submission._id, {
        _id: this.submission._id,
        survey_id: this.survey._id,
        user_id: this.authService.getUser()._id,
        event_id: this.eventId,
        userData: this.formRef.userData
      });
    }
    obs.subscribe(
      success => {
        this.toastr.success('Your survey has been submitted successfully.');
        this.router.navigateByUrl('/surveys');
      },
      error => {
        console.error('SurveySubmission', error);
        this.toastr.error(error);
      }
    )
  }

  deleteSubmission(subission: any) {
    this.surveySubmissionService.remove(subission._id).subscribe(
      success => {
        this.toastr.success('Your survey submission has been deleted.');
        this.router.navigateByUrl('/surveys');
      },
      error => {
        console.error('DeleteSurveySubmission', error);
        this.toastr.error('Could not delete your survey submission.');
      }
    )
  }

}
