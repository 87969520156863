import { Injectable } from '@angular/core';
import { Feathers } from '../feathers.service';
import { BaseService } from '../base.service';
import { forkJoin, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthService } from '../auth.service';

@Injectable()
export class MessageService extends BaseService<any> {
  constructor(
    protected feathers: Feathers,
    protected http: HttpClient,
    protected auth: AuthService
  ) {
    super(feathers, 'messages');
  }

  getAllMessages(session_id: string, limit: number = 50) {
    const options: any = { 
      headers :  new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.auth.authData.accessToken
      }),
      params: new HttpParams().set('session_id', session_id).set('$limit', String(0))
    }
    return this.http.get(`${this.feathers.instanceUrl}/messages`, options).pipe(
      switchMap((data: any) => {
        let obs = [];
        const header: any = { 
          headers :  new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': this.auth.authData.accessToken
          })
        }
        const maxI = Math.round(data.total/limit) > 0 ? Math.round(data.total/limit) : 1;
        for (let i = 0; i < maxI; i++) {
          obs.push(
            this.http.get(`${this.feathers.instanceUrl}/messages`, Object.assign(header, {params: new HttpParams().set('session_id', session_id).set('$limit', String(limit)).set('$skip', String(i*limit))}))
          );
        }
        return forkJoin(...obs);
      })
    );
  }

  watch(filter ? : any): Observable < any > {
    const defaultFilter = {
      query: {
        $sort: {
          createdAt: -1
        },
        $limit: 25
      }
    };
    return super.watch(filter ? filter : defaultFilter).pipe(
      map((messages:any) => messages.map((m:any) => {
        m.content = decodeURIComponent(m.content);
        return m;
      }))
    );
  }

  send(message: any) {
    message.content = encodeURIComponent(message.content);
    return super.create(message);
  }

}
