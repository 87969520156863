import { Component, ChangeDetectionStrategy, OnDestroy, OnInit,} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ExhibitorService } from 'src/app/services/exhibitor/exhibitor.service';
import { LivechatService } from 'src/app/services/livechat/livechat.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'vc-industrieaussteller',
  templateUrl: './industrieaussteller.component.html',
  styleUrls: ['./industrieaussteller.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IndustrieausstellerComponent implements OnInit, OnDestroy {
  selectedEx: any;
  exhibitors: any = [];
  activeID: number;
  sponsorID: number;

  constructor(
    private sanitizer: DomSanitizer,
    private exhibitorService: ExhibitorService,
    private liveChatService: LivechatService,
    private route: ActivatedRoute
  ) { 
  }

  ngOnInit() {
    var sub = this.route.params.subscribe(params => {
      this.sponsorID = +params['sponsorID'];
    });

    this.exhibitorService.get().subscribe(data => {
      this.exhibitors = data;
      if(this.sponsorID){
        this.selectEx(this.sponsorID)
      }else{
        const max = Array.isArray(data) ? this.exhibitors.length-1 : 0;
        this.selectEx(Math.floor(Math.random() * (max - 0 + 1) + 0));
      }
    });
  }

  ngOnDestroy() {    
    this.liveChatService.cleanupLiveChat();
  }
  
  selectEx(id) {
    if (this.selectedEx && this.selectedEx[0] && (this.selectedEx[0].id === id)) {
      return;
    }
    window.scrollTo(0,0);
    this.liveChatService.cleanupLiveChat();
    this.selectedEx = [];
    this.activeID = id;
    this.selectedEx = [this.exhibitors.find(e => e.id === id)];
    if (this.selectedEx[0]) {      
      this.exhibitorService.trackEvent(this.selectedEx[0].title , 'open page', 'industrieausstellung');
    }
    if (this.selectedEx[0] && this.selectedEx[0].livechat_uuid) {
      this.liveChatService.createLiveChat(this.selectedEx[0].livechat_uuid, this.selectedEx[0].livechat_service);
    }
  }

  safeUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }



}
