import { Component, AfterViewInit, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { SurveyService } from 'src/app/services/survey/survey.service';
import { ToastrService } from 'ngx-toastr';
declare var $: any;

@Component({
  selector: 'vc-form-builder',
  templateUrl: './form-builder.component.html',
  styleUrls: ['./form-builder.component.scss']
})
export class FormBuilderComponent implements AfterViewInit, OnDestroy {
  eventId: string = 'VdoUjTaifwXRdVsI'; // TODO get eventId from service
  formBuilder: any;
  surveyTitle: string = '';
  options = {
    onSave: () => {
      console.log('this.formData', this.formBuilder.formData);
      // check if form has a submit button
      if (!this.formBuilder.formData) {
        return;
      }
      let hasSubmit:boolean = false;
      JSON.parse(this.formBuilder.formData).forEach(element => {
        if ((element.type === 'button') && (element.subtype === 'submit')) {
          hasSubmit = true;
        }
      });
      if (!hasSubmit) {
        this.toastr.error('The form has to contain a button of the type "submit".');
        return;
      }
      // save formData to server
      this.surveyService.create({
        event_id: this.eventId,
        label: this.surveyTitle,
        public: true,
        formData: this.formBuilder.formData
      }).subscribe(
        data => {
          this.toastr.success('You will be redirected shortly.', `Survey has been created!`);
          setTimeout(() => this.router.navigate(['/survey', data._id]), 250);
        },
        error => {
          console.error('Error', error)
          this.toastr.success(`There was an error during the survey's creation!`);
        }
      );
    },
  };

  constructor(
    private authService: AuthService,
    private router: Router,
    private surveyService: SurveyService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
   if (this.authService.getEventRole(this.eventId) != 'admin') {
    this.router.navigateByUrl('/');
   }
  }

  ngOnDestroy() {
  }

  ngAfterViewInit() {
    this.formBuilder = $('#formBuilder').formBuilder(this.options);
  }

}
