import { Component, OnChanges, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
// Services
import { FileService } from 'src/app/services/file/file.service';

@Component({
  selector: 'vc-async-img',
  templateUrl: './async-img.component.html',
  styleUrls: ['./async-img.component.scss']
})
export class AsyncImgComponent implements OnChanges {
  @Input() id:string;
  img: BehaviorSubject<string> = new BehaviorSubject<string>('');
  type: 'video' | 'image' = 'image';

  constructor(
    private fileService: FileService,
  ) { }

  ngOnChanges() {
    if (this.id) {
      this.fileService.getSrc(this.id).subscribe(data => {
        if (data.uri.startsWith('data:video')) {
          this.type = 'video';
        } else {
          this.type = 'image';
        }
        return this.img.next(data.uri);
      });
    }
  }

}
