import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
// Services
import { AuthService } from 'src/app/services/auth.service';
import { EventService } from 'src/app/services/event/event.service';
import { SessionService } from 'src/app/services/session/session.service';
// Utils
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'vc-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  events: any[] = [];
  sessions: any[] = [];
  liveSessions: any[] = [];
  $destroyed: Subject<boolean> = new Subject<boolean>();
  user: any;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private eventService: EventService,
    private sessionService: SessionService,
    private authService: AuthService,
  ) {}

  getEventRole = (event_id:string) => this.authService.getEventRole(event_id);

  ngOnInit() {
    // load events
    this.subscribeToEvents();
    // clear active event
    this.eventService.$activeEvent.next(null);
  }

  ngOnDestroy() {
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  subscribeToEvents() {
    this.eventService.watch().pipe(
      takeUntil(this.$destroyed.asObservable()),
    ).subscribe(
      data => this.events = data,
      error => this.toastr.error('Could not load events!')
    );    
  }

  subscribeToSessions() {
    this.sessionService.watch({
      query: { 
          status: "live",
          $limit: 100,
      }
    }).pipe(
      takeUntil(this.$destroyed.asObservable()),
    ).subscribe(
      data => this.sessions = data,
      error => this.toastr.error('Could not load sessions!')
    );    
  }

  filterLiveSessions(events: any, sessions:any) {
    // TODO hierfür müssen die channel abgefragt werden
    // for (let event of events) {
    //   if (event.)
    // }
  }

}
