import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
// Services
import { AuthService } from './services/auth.service';
import { EventService } from './services/event/event.service';

@Component({
  selector: 'vc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
  activeUrl: string;
  loggedIn: boolean = false;
  activeEvent: any = {_id: "VdoUjTaifwXRdVsI"};
  isCollapsed:boolean = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private location: Location,
    private authService: AuthService,
    private eventService: EventService,
  ) {}

  ngOnInit() {
    this.authService.logIn().then(data => {
      if (data && data.user) {
        this.loggedIn = true;
      } else {
        this.loggedIn = false
      }
    });
    this.eventService.$activeEvent.subscribe(data => {
      // angular lifecylce timing workaround
      setTimeout(() => this.activeEvent = data, 0);
    });
  }

  toggleNav = () => this.isCollapsed ? this.isCollapsed = false : this.isCollapsed = true;

  logout() {
    this.authService.logOut();
  }

}
