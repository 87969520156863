import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { SurveySubmissionService } from 'src/app/services/survey/survey-submission.service';
import { SurveyService } from 'src/app/services/survey/survey.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'vc-form-list',
  templateUrl: './form-list.component.html',
  styleUrls: ['./form-list.component.scss']
})
export class FormListComponent implements OnInit, OnDestroy {
  eventId: string = 'VdoUjTaifwXRdVsI'; // TODO get eventId from service
  isAdmin: boolean = false;
  $destroyed: Subject<boolean> = new Subject<boolean>();
  surveys: any[] = [];

  constructor(
    private authService: AuthService,
    private surveyService: SurveyService,
    private surveySubmissionService: SurveySubmissionService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.isAdmin =  this.authService.getEventRole(this.eventId) === 'admin';
    this.subscribeToSurveys();
  }
      
  ngOnDestroy() {
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  subscribeToSurveys() {
  this.surveyService.watch({
    query: { 
      event_id: this.eventId,
      $limit: 100,
    }
  }).pipe(
    takeUntil(this.$destroyed.asObservable()),
  ).subscribe(
    data => {
      this.surveys = data;
    },
    error => {
      console.error(error);
      this.toastr.error('Could not load surveys!');
    }
  );  
  }

  deleteSurvey(survey: any) {
    this.surveyService.remove(survey._id).pipe(take(1)).subscribe(
      success => {
        this.toastr.success(`Deleted survey "${survey.label}".`);
      },
      error => {
        console.error('[deleteSurvey@form-list.comp]', error);
        this.toastr.error('Could not delete survey.');
      }
    )
  }

  downloadSurveyData(survey: any) {
    this.surveySubmissionService.watch({
      query: {
        "survey_id": survey._id,
        $limit: 1000,
      }
    }).pipe(
      takeUntil(this.$destroyed.asObservable()),
    ).subscribe(
      success => {
        this.exportAsXslx(survey, success);
      },
      error => {
        console.error('[downloadSurveyData@form-list.comp]', error);
        this.toastr.error('Could download the survey data.');
      }
    )
  }

  exportAsXslx(survey:any, data:any) {
    if (!data || !data[0] || !data[0].userData) {
      if (Array.isArray(data)) {
        this.toastr.warning('There are no submission for this survey yet.');
      } else {
        this.toastr.error('The surveys data is in a wrong format.');
      }
      return;
    }
    // create workbook
    const wb = XLSX.utils.book_new();
    // helpers
    const replaceWithLabel = (value: string, values: any) => {
      const label = values.find(v => v.value === value);
      if (label) {
        return label.label;
      }
      return value;
    };
    // sheet 1 - user entries    
    const sheet1 = (data) => {
      // filter inputs with name (real input, no p oder headline) wihtout buttons
      const inputs = data[0].userData.filter(e => e.name).filter(e => e.type !== 'button').map(e => {
        if (e.name) {
          return e;
        }
      });
      // map data to the inputs values
      data = data.map(e => {
        const data = [e._id, e.user_id];
        inputs.forEach(input => {
          const entry = e.userData.find(e => e.name === input.name);
          if (entry) {
            let value = entry.userData
            if (Array.isArray(value)) {              
              if (entry.values) {
                value = value.map(oneValue => replaceWithLabel(oneValue, entry.values));
              }
              value = value.join(', ');
            }  else {
              value = replaceWithLabel(value, entry.values);
            }
            data.push(value);
          } else {
            data.push('-');
          }
        });
        return data;
      });
      // create header
      const header = ['id', 'user_id'];
      inputs.forEach(input => {
        header.push(input.label);
      });
      // make sheet
      return XLSX.utils.aoa_to_sheet([header, ...data]); 
    }       
    // sheet 2 - results
    const sheet2 = (data) => {
      const rows = [];
      // create field-value map
      const map = {};
      data[0].userData.forEach(field => {
        if (!field.values || (field.values.length === 0)) {
          return;
        }
        map[field.name] = {};
        field.values.forEach(value => {
          map[field.name][value.value] = Object.assign(value, { count: 0 });
        });
      });
      // loop all entries to gather value counts
      const increaseCount = (fieldName:string, value:string) => {
        map[fieldName][value].count++;
      }
      data.forEach(entry => {
          entry.userData.forEach(field => {
            if (field.userData && field.type.includes('group')) {
              if (Array.isArray(field.userData)) {
                field.userData.forEach(value => {
                  increaseCount(field.name, value);
                });
              } else {
                increaseCount(field.name, field.userData)
              }
            }
          });
      });
      // loop all fields
      data[0].userData.forEach(field => {
        if (!field.values || (field.values.length === 0)) {
          return;
        }
        // header line
        if (rows.length !== 0) {
          rows.push([]);
        }
        rows.push(['Question', '', field.label]);
        rows.push([]);
        // add vales and counts
        field.values.forEach(value => {
          rows.push(['', '', value.label, value.count])
        });
      });
      // make sheet
      return XLSX.utils.aoa_to_sheet(rows);
    }
    // add worksheets to workbook
    XLSX.utils.book_append_sheet(wb, sheet1(data), 'entries');
    XLSX.utils.book_append_sheet(wb, sheet2(data), 'results');
    // write workbook
    XLSX.writeFile(wb, `${survey.label}_${survey._id}_${new Date().getTime()}.xlsx`);
  }

  togglePublicState(survey: any) {
    this.surveyService.patch(survey._id, {
      public: !survey.public
    }).pipe(take(1)).subscribe(
      success => {
        this.toastr.success(`Survey "${survey.label}" was updated successfully.`);
      },
      error => {
        console.error('[togglePublicState@form-list.comp]', error);
        this.toastr.error(`Could not update survey "${survey.label}".`);
      }
    )
  }

}
