import { Injectable } from '@angular/core';
import { Feathers } from '../feathers.service';
import { BaseService } from '../base.service';
import { map, take } from 'rxjs/operators';

@Injectable()
export class UploadInEventService extends BaseService<any> {

  constructor(
    protected feathers: Feathers
  ) {
    super(feathers, 'upload-in-event');
  }
  
  getUploadsBySession(sessionId:string, limit:number = 100) {
    return super.watch({
      query: {
        session_id: sessionId,
        $limit: limit,
      }
    }).pipe(
      take(1),
      // map(e => e[0]),
    );
  }

}
