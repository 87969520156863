import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ExhibitorService } from 'src/app/services/exhibitor/exhibitor.service';

@Component({
  selector: 'vc-sponsor',
  templateUrl: './sponsor.component.html',
  styleUrls: ['./sponsor.component.scss']
})
export class SponsorComponent implements OnInit {
  exhibitors: any = [];

  constructor(
    private sanitizer: DomSanitizer,
    private exhibitorService: ExhibitorService,
  ) { }

  ngOnInit() {
    this.exhibitorService.get().subscribe(data => this.exhibitors = data);
  }

}
