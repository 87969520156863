import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, Input, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
// Services
import { AuthService } from 'src/app/services/auth.service';
import { ChannelService } from 'src/app/services/channel/channel.service';
import { EventService } from 'src/app/services/event/event.service';
import { MessageService } from 'src/app/services/message/message.service';
import { SessionService } from 'src/app/services/session/session.service';
// Utils
import { map, takeUntil, tap, delay } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { sortBy } from 'lodash';

@Component({
  selector: 'vc-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('messageList', {static: true}) messageList:ElementRef;
  @Input('session') session:any;
  messageContent: string;
  messages: any[] = [];
  users: any[];
  user: any;
  $destroyed: Subject<boolean> = new Subject<boolean>();
  spamCount: number = 0;

  constructor(
    private messageService: MessageService,
    private toastr: ToastrService,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    this.user = this.authService.getUser();
  }

  ngOnChanges() {
    this.messages = [];
    this.getAllMessages();
  }
  
  ngOnDestroy() {
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  getAllMessages() {
    this.messageService.getAllMessages(this.session._id).subscribe(
      data => {
        let allMessages = [].concat(...data.map(e => e.data));
        allMessages = allMessages.map(m => {
          m.content = decodeURIComponent(m.content);
          return m;
        });
        allMessages = sortBy(allMessages, 'createdAt');
        this.messages = allMessages;
        // join realtime chat
        this.join();
      }      
    )
  }

  join() {
    this.messageService.watch({
      query: {
        session_id: this.session._id,
        $sort: {
          createdAt: -1
        },
        $limit: 5
      }
    }).pipe(
      takeUntil(this.$destroyed.asObservable()),
      // reverse the messages array, to have the most recent message at the end
      // necessary because we get a descendingly sorted array from the data service
      map((m: Array<any>) => m.reverse())
    ).subscribe(
      messages => {
        if (messages.length === 0) {
          this.messages = [];
        } else {
          this.messages = this.appendMessages(messages, this.messages);
        }
        // keep chat scrolled to bottom
        setTimeout(() => {
          this.messageList.nativeElement.scrollTop = this.messageList.nativeElement.scrollHeight; 
        }, 0)
      },      
      error => {
        this.toastr.error('Could not join chat!');
        console.error('[chat.component]', error)
      }
    )
  }

  appendMessages(newMessages: any[], oldMessages: any[]) {
    for (let m of newMessages) {
      if (!oldMessages.find(oM => oM._id === m._id)) {
        oldMessages.push(m);
      }
    }
    return oldMessages;
  }

  sendMessage() {
    if (!this.session._id || !this.user._id || ! this.session.event_id || !this.messageContent || this.messageContent.length === 0) {
      console.warn('Missing parameters for message.');
      return;
    }
    this.messageService.send({
      session_id: this.session._id,
      user_id: this.user._id,
      event_id: this.session.event_id,
      content: this.messageContent,
    }).subscribe(
      data => {    
        this.messageContent = '';
        // this.messageCooldown = true;
        // setTimeout(() => {
        //   this.messageCooldown=false;
        // }, 10000);
        this.spamCount++;
        setTimeout(() => {
          this.spamCount--;
        }, 7000);
      }
    );
  }
  
}
