import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
// Services
import { AuthService } from 'src/app/services/auth.service';
import { ChannelService } from 'src/app/services/channel/channel.service';
import { EventService } from 'src/app/services/event/event.service';
import { SessionService } from 'src/app/services/session/session.service';
import { UploadInEventService } from 'src/app/services/uploads/upload-in-event.service';
// Component
import { ChannelCreateModalComponent } from '../channel-create-modal/channel-create-modal.component';
import { SessionModalComponent } from '../session-modal/session-modal.component';
import { SessionEditModalComponent } from '../session-edit-modal/session-edit-modal.component';
import { ChannelEditModalComponent } from '../channel-edit-modal/channel-edit-modal.component';
// Utils
import { takeUntil, tap } from 'rxjs/operators';
import { Subject, Observable, } from 'rxjs';

@Component({
  selector: 'vc-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})
export class EventComponent implements OnInit, OnDestroy {  
  event: any;
  channels: any[] = [];
  sessions: any[] = [];
  filteredSessions: any[] = [];
  $destroyed: Subject<boolean> = new Subject<boolean>();
  readMore: boolean = false;
  sessionOrder = ['day', 'start_time', 'name'];
  channelOrder = ['order', 'title'];
  sessionFitlerByChannelId: string;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private location: Location,
    private authService: AuthService,
    private eventService: EventService,
    private sessionService: SessionService,
    private channelService: ChannelService,
    private modalService: BsModalService
  ) {}

  ngOnInit() {
    this.route.params.subscribe(
      (data: any) => {
        if (Object.keys(data).includes('eventId') && data['eventId']) { 
          
            this.getEvent(data['eventId']).subscribe(
              event => {                
                this.event = event;
                this.subscribeToSessions();
                this.subscribeToChannels();
              },
              error => {
                this.toastr.error('Could not load event!');
                this.router.navigate(['/dashboard']);
              }
            );
        } else {
            this.toastr.error('No matching event found!');
            this.location.back();
        }
      },
      error => {
        this.toastr.error('Cloud not load event!');
        this.location.back();
      }
    );
  }
  
  ngOnDestroy() {
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  getEvent(eventId: string):Observable<any> {
    return this.eventService.get(eventId).pipe(
      tap(e => this.eventService.$activeEvent.next(e)),
    );    
  }

  subscribeToSessions() {
    this.sessionService.watch({
      query: { 
        event_id: this.event._id,
        $limit: 100,
      }
    }).pipe(
      takeUntil(this.$destroyed.asObservable())
    ).subscribe(
      data => {
        this.sessions = data;
        if (this.sessionFitlerByChannelId) {
          this.filteredSessions = data.filter(s => s.channel_id === this.sessionFitlerByChannelId);
        } else {
          this.filteredSessions = data;
        }
      },
      error => {
        console.error(error);
        this.toastr.error('Could not load sessions!');
      }
    );    
  }

  subscribeToChannels() {
    this.channelService.watch({
      query: { 
        event_id: this.event._id,
        $limit: 25,
      }
    }).pipe(
      takeUntil(this.$destroyed.asObservable()),
    ).subscribe(
      data => this.channels = data,
      error => {
        console.error(error);
        this.toastr.error('Could not load channels!');
      }
    );    
  }

  getEventRole = (event_id:string) => this.authService.getEventRole(event_id);

  openSessionModal(session:any) {
    const modalRef: BsModalRef = this.modalService.show(SessionModalComponent);
    modalRef.content.session = session;
  }

  findSession(session_id:string) {
    return this.sessions.find(s => s._id === session_id);
  }

  openNewChannelModal() {
    const modalRef: BsModalRef = this.modalService.show(ChannelCreateModalComponent);
    modalRef.content.event_id = this.event._id;
  }

  editSession(session:any) {
    if (!session) {
      this.toastr.warning('No session provided.');
      return;
    }
    const modalRef: BsModalRef = this.modalService.show(SessionEditModalComponent);
    modalRef.content.session = session;
  }

  editChannel(channel:any) {
    if (!channel || !this.sessions) {
      this.toastr.warning('Missing data provided.');
      return;
    }
    const modalRef: BsModalRef = this.modalService.show(ChannelEditModalComponent, {
      initialState: {
        channel: channel,
        sessions: this.sessions.filter(s => s.channel_id === channel._id),
      }
    });
  }

  getChannelName(channel_id: string) {
    const channel = this.channels.find(c => c._id === channel_id);
    if (channel) {
      return channel.name;
    }
    return 'Unkown channel.'
  }

  filterSessionsByChannelId(channel_id: string) {
    if (this.sessionFitlerByChannelId === channel_id) {
      this.sessionFitlerByChannelId = undefined;
      this.filteredSessions = this.sessions;
    } else {
      this.sessionFitlerByChannelId = channel_id;
      this.filteredSessions = this.sessions.filter(s => s.channel_id === channel_id);
    }
  }

}
