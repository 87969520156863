import { Injectable } from '@angular/core';
declare var window: any;

@Injectable({
  providedIn: 'root'
})
export class LivechatService {

  constructor() { }

  createLiveChat(id:string, service: string = 'liveagent') {
    console.log('Create LiveChat', service, id);
    // check cookie conesnt
    // const cookie = document.cookie.match(/(;)?cookiebar=([^;]*);?/);
    // if (cookie && cookie[2]) {
    //   const cookieValue = cookie[2];
    //   if (cookieValue !== 'CookieAllowed') {
    //     return;
    //   }
    // } else {
    //   return;
    // }
    if (!id) {
      console.error('[createLiveAgent] Missing ID.')
      return;
    }
    this.cleanupLiveChat();
    if (service === 'liveagent') {
      this.createLiveAgentChat(id);
    } else if (service === 'tawk') {
      this.createTawkChat(id);
    }
  }

  createLiveAgentChat(id: string) {
    // create new button
    let scriptUrl = 'https://vctest.ladesk.com/scripts/track.js';
    let node = document.createElement('script');
    node.src = scriptUrl;
    node.id = 'la_x2s6df8d';
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    node.onload = function (e) {
      window.LiveAgent.createButton(id, document.getElementById("chatButton"));
    };
    document.querySelector('head').appendChild(node);
  }

  createTawkChat(id: string) {
    var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
    (function(){
    var s1=document.createElement("script"); //s0=document.getElementsByTagName("script")[0];
    s1.async=true;
    s1.src = `https://embed.tawk.to/${id}`;
    s1.charset='UTF-8';
    s1.setAttribute('crossorigin','*');
    // s0.parentNode.insertBefore(s1,s0);
    document.querySelector('body').append(s1);
    })();
  }

  cleanupLiveChat() {
    // cleanup tawk.to    
    document.querySelectorAll('body script').forEach((e:any) => {
      if (e.src && e.src.includes('tawk.to')) {
        e.remove();
        document.querySelector('body').lastChild.remove();
        const css: any = document.querySelector('head').lastChild;
        if (css && css.innerText && css.innerText.includes('tawk')) {
          document.querySelector('head').lastChild.remove();
        }
        delete window.TawkClass;
        delete window.Tawk_API;
        delete window.$_Tawk_WidgetId;
        delete window.$_Tawk;
        delete window.$_Tawk_AccountKey;
        delete window.$_Tawk_Unstable;
      }
    });
    // cleanup liveagent.com
    if (document.querySelector('#la_x2s6df8d')) {
      if (document.querySelector('#la_x2s6df8d').nextElementSibling) {
        document.querySelector('#la_x2s6df8d').nextElementSibling.remove();
      }
      document.querySelector('#la_x2s6df8d').remove();
      if (document.querySelector('#chatButton')) {
        document.querySelector('#chatButton').innerHTML = "";
      }
    }
    document.querySelectorAll('iframe').forEach(e => {
      if (e.src.includes('ladesk.com')) {
        e.remove();
      }
    });
  }

}
