import { Injectable } from '@angular/core';
import { Feathers } from '../feathers.service';
import { BaseService } from '../base.service';

@Injectable()
export class SurveyService extends BaseService<any> {

  constructor(
    protected feathers: Feathers
  ) {
    super(feathers, 'survey');
  }

}
