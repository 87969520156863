import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Feathers } from '../../services/feathers.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'vc-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent {

  constructor(
    private feathers: Feathers,
    private router: Router,
    private toastr: ToastrService,
  ) {}

  validate = (data: any) => {
    let valid = true;
    const requried = ['email', 'password', 'username', 'invite_code'];
    for (let r of requried) {
      if (data[r] === undefined) {
        this.toastr.error('Missing proptery: ' + r);
        valid = false;
      }
      if (typeof data[r] === 'string' && data[r].length === 0) {
        this.toastr.error(`Reqired proptery ${r} can not be emty.`);
        valid = false;
      }
    }
    return valid;
  }

  signup(email: string, password: string, username: string, inviteCode: string) {
    const user = {
      email: email,
      password: password,
      username: username,
      invite_code: inviteCode,
    };
    if (this.validate(user)) {
      this.feathers.service('users')
        .create(user)
        .then((data) => {
          this.toastr.success('User created.');
          // try to authenticate with feathers
          this.feathers.authenticate({
            strategy: 'local',
            email,
            password
          })
            // navigate to base URL on success
            .then(() => {
              this.router.navigate(['/']).then(e => {
                // TODO user differenc approach to refresh app.component 
                window.location.reload();
              });
            })
            .catch(err => {
              this.toastr.error('Wrong credentials!')
            });
        })
        .catch(err => {
          console.error('[signup] createdUser', err);
          this.toastr.error('Could not create user!');
        });
    }
  }
}
