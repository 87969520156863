import { Injectable } from '@angular/core';
import { Feathers } from '../feathers.service';
import { BaseService } from '../base.service';
import { Subject } from 'rxjs';

@Injectable()
export class EventService extends BaseService<any> {
  activeEvent: any;
  $activeEvent: Subject<any> = new Subject();

  constructor(
    protected feathers: Feathers
  ) {
    super(feathers, 'event');
  }

}
