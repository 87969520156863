import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-channel-edit-modal',
  templateUrl: './channel-edit-modal.component.html',
  styleUrls: ['./channel-edit-modal.component.scss']
})
export class ChannelEditModalComponent {
  channel:any;
  sessions: any;

  constructor(
    public bsModalRef: BsModalRef,
  ) { }

}
