import { Injectable } from '@angular/core';
import { Feathers } from '../feathers.service';
import { BaseService } from '../base.service';
import { map } from 'rxjs/operators';

@Injectable()
export class ChannelService extends BaseService<any> {
  constructor(
    protected feathers: Feathers
  ) {
    super(feathers, 'channel');
  }

  watchOne(channelId) {
    return super.watch({
      query: {
        _id: channelId,
      }
    }).pipe(
      map(e => e[0]),
    );
  }

}
