import { Injectable } from '@angular/core';
import { Feathers } from '../feathers.service';
import { BaseService } from '../base.service';
import { map } from 'rxjs/operators';
import { UploadInEventService } from '../uploads/upload-in-event.service';

@Injectable()
export class SessionService extends BaseService<any> {

  constructor(
    protected feathers: Feathers,
    private uploadInEventService: UploadInEventService,
  ) {
    super(feathers, 'session');
  }
  
  watch(filter?: any) {
    return super.watch(filter).pipe(
      map(e => {
        return e.map(session => {
          session.day = new Date(session.day).getTime();
          const start = new Date(session.day);
          start.setHours(new Date(session.start_time).getHours());
          start.setMinutes(new Date(session.start_time).getMinutes());
          session.start_time = start.getTime();          
          const end = new Date(session.day);
          end.setHours(new Date(session.end_time).getHours());
          end.setMinutes(new Date(session.end_time).getMinutes());
          session.end_time = end.getTime();
          if (!session.uploads) {
            this.uploadInEventService.getUploadsBySession(session._id).subscribe(
              data => {
                session.uploads = [...data];
              }
            )
          }
          return session;
        });
      })
    )
  }

  watchOne(sessionId:string, limit:number = 100) {
    return super.watch({
      query: {
        _id: sessionId,
        $limit: limit,
      }
    }).pipe(
      map(e => e[0]),
    );
  }

}
